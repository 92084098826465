import { useRef } from 'react';
import { Link } from 'react-scroll';
import { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';

import './styles/App.css';
import ServiceInfoCard from './components/ServiceInfoCard';
import ClientsSlider from './components/ClientsSlider';

import logo from '../src/assets/images/logo.svg';
import idFlag from '../src/assets/icons/idFlag.png';
import enFlag from '../src/assets/icons/enFlag.svg';
import jumbotronImage from '../src/assets/images/jumbotronImage.svg';
// our team photo
import xenaRaihan from '../src/assets/images/xenaRaihan.png';
import radiasMahesG from '../src/assets/images/radiasMahesG.png';
import habibRahmatullahR from '../src/assets/images/habibRahmatullahR.png';
// our service image
import instagram from '../src/assets/icons/instagram.svg';
import mic from '../src/assets/icons/mic.svg';
import building from '../src/assets/icons/building.svg';
import symbolPhoto from '../src/assets/icons/symbolPhoto.svg';
import pencil from './assets/icons/pencil.svg';
import blobShape from './assets/images/blobShape.svg';
// clients image
import PKR from './assets/images/PKR.png';
import kabupatenSanggau from './assets/images/kabupatenSanggau.png';
import surakarta from './assets/images/surakarta.png';
import pixel from './assets/images/pixel.png';
import indeks from './assets/images/indeks.png';
import baba from './assets/images/baba.png';
import betty from './assets/images/betty.png';
import gas from './assets/images/gas.png';
import SH from './assets/images/SH.png';
import kripton from './assets/images/kripton.png';
import Dtrans from './assets/images/Dtrans.png';
import BTFatma from './assets/images/BTFatma.jpg';
import carolin from './assets/images/carolin.png';
import clairEssential from './assets/images/clairEssential.png';
import DKU from './assets/images/DKU.png';
import dtp from './assets/images/dtp.png';
import gudegMbakYus from './assets/images/gudegMbakYus.png';
import monkey from './assets/images/monkey.JPG';
import teradisional from './assets/images/teradisional.png';
// galerry photo
import teamPhoto1 from './assets/images/teamPhoto1.jpg';
import teamPhoto2 from './assets/images/teamPhoto2.jpg';
import teamPhoto3 from './assets/images/teamPhoto3.jpg';
import teamPhoto4 from './assets/images/teamPhoto4.jpg';
import teamPhoto5 from './assets/images/teamPhoto5.jpg';
import teamPhoto6 from './assets/images/teamPhoto6.jpg';
import teamPhoto7 from './assets/images/teamPhoto7.jpg';
import teamPhoto8 from './assets/images/teamPhoto8.jpg';
import teamPhoto9 from './assets/images/teamPhoto9.jpg';
import teamPhoto10 from './assets/images/teamPhoto10.jpg';
import teamPhoto11 from './assets/images/teamPhoto11.jpg';
import teamPhoto12 from './assets/images/teamPhoto12.jpg';
// footer image
import whatsApp from './assets/icons/whatsApp.svg';
import whiteInstagram from './assets/icons/whiteInstagram.svg';
import facebook from './assets/icons/facebook.svg';
import linkedin from './assets/icons/linkedin.svg';
import email from './assets/icons/email.svg';
import telephone from './assets/icons/telephone.svg';
import wave from './assets/images/wave.png';
import abstractElips from './assets/images/abstractElips.png';
import work from './assets/images/work.png';
import look from './assets/images/look.png';
import device from './assets/images/device.png';

function App() {
  const [showSentNotif, setShowSentNotif] = useState(false);

  // Data
  const teamMembers = [
    {
      name: 'Xena Raihan',
      photo: xenaRaihan,
      position: 'Marketing Communication',
    },
    {
      name: 'Radias Mahes G',
      photo: radiasMahesG,
      position: 'Graphic Designer',
    },
    {
      name: 'Habib Rahmatullah R',
      photo: habibRahmatullahR,
      position: 'Photographer & Videographer',
    },
  ];

  const serviceData = [
    {
      serviceImage: instagram,
      serviceTitle: 'Konten Media Sosial',
      serviceDescription:
        'Berisi logo, judul layanan, dan deskripsi layanan dalam sebuah section yang menjelaskan layanan dari sebuah website company profil',
      serviceTitleEnglish: 'Social Media Content',
      serviceDescriptionEnglish:
        'Output from the management of social media content on the Instagram or Facebook application in the form of photos, video reels, and graphic designs to increase the interest of potential buyers and the selling power of products.',
    },
    {
      serviceImage: mic,
      serviceTitle: 'Produk Audio Visual',
      serviceDescription:
        'Produk audio visual mengenai bisnis usaha yang dijalankan dapat berupa series, film pendek, TVC, Music Video, hingga podcast dengan konsep yang selalu mengalami pembaruan dan disesuaikan dengan target client/ konsumen dan ketertarikan masyarakat zaman sekarang',
      serviceTitleEnglish: 'Audio Visual Product',
      serviceDescriptionEnglish:
        "Audio-visual products are in series, short films, TVC, Music Videos, and podcast forms with updated concepts and adapted to the target client/consumer and the interests of today's society.",
    },
    {
      serviceImage: building,
      serviceTitle: 'Video Profil Perusahaan',
      serviceDescription:
        'Memperkenalkan bisnis perusahaan anda melalui media digital dalam bentuk video. Video dapat berbentuk pengenalan bisnis secara menyeluruh ataupun hanya fokus kepada produk-produk yang ditawarkan.',
      serviceTitleEnglish: 'Company Profile Video',
      serviceDescriptionEnglish:
        "Introduce your company's business through digital media in video form. Videos output takes the form of a thorough business introduction or only focuses on the products offered.",
    },
    {
      serviceImage: symbolPhoto,
      serviceTitle: 'Foto Produk',
      serviceDescription:
        'Pengambilan gambar produk berupa foto kreatif yang disesuaikan dengan tema dan kebutuhan jenis usaha. Keperluan pengambilan gambar dengan model kami sediakan sehingga client dapat memilih sendiri. Photo product konten hanya dilakukan untuk satu kali project sesuai dengan paket layanan yang kami berikan.',
      serviceTitleEnglish: 'Product Pictures',
      serviceDescriptionEnglish:
        'Take product pictures in the form of creative photos adapted to the theme and needs of the type of business. We provide models according to the shooting needs that clients can choose. Filling in photo products is done for one project according to the service package we provide.',
    },
    {
      serviceImage: pencil,
      serviceTitle: 'Desain Logo',
      serviceDescription:
        'Identitas yang dibutuhkan oleh perusahaan atau merek berupa ide dan desain dengan memberikan beberapa macam paket yang diperlukan. Mengutamakan originalitas dan kepuasan klien untuk perusahaan atau merek.',
      serviceTitleEnglish: 'Logo Design',
      serviceDescriptionEnglish:
        'The identity needed by a company or brand is in the form of ideas and designs by providing various packages. Prioritize originality and client satisfaction for the company or brand.',
    },
  ];

  const clientsData = [
    {
      name: 'Partai Keadilan Rakyat',
      image: PKR,
    },
    {
      name: 'Kabupaten Sanggau',
      image: kabupatenSanggau,
    },
    {
      name: 'Kota Surakarta',
      image: surakarta,
    },
    {
      name: 'Pixel Communication',
      image: pixel,
    },
    {
      name: 'Indeks',
      image: indeks,
    },
    {
      name: 'Baba',
      image: baba,
    },
    {
      name: 'Betty',
      image: betty,
    },
    {
      name: 'GAS',
      image: gas,
    },
    {
      name: 'SH',
      image: SH,
    },
    {
      name: 'Kripton',
      image: kripton,
    },
    {
      name: 'Dtrans',
      image: Dtrans,
    },
    {
      name: 'BTFatma',
      image: BTFatma,
    },
    {
      name: 'carolin',
      image: carolin,
    },
    {
      name: 'clairEssential',
      image: clairEssential,
    },
    {
      name: 'DKU',
      image: DKU,
    },
    {
      name: 'dtp',
      image: dtp,
    },
    {
      name: 'gudegMbakYus',
      image: gudegMbakYus,
    },
    {
      name: 'monkey',
      image: monkey,
    },
    {
      name: 'teradisional',
      image: teradisional,
    },
    // same client data (doubled)
    {
      name: 'Partai Keadilan Rakyat',
      image: PKR,
    },
    {
      name: 'Kabupaten Sanggau',
      image: kabupatenSanggau,
    },
    {
      name: 'Kota Surakarta',
      image: surakarta,
    },
    {
      name: 'Pixel Communication',
      image: pixel,
    },
    {
      name: 'Indeks',
      image: indeks,
    },
    {
      name: 'Baba',
      image: baba,
    },
    {
      name: 'Betty',
      image: betty,
    },
    {
      name: 'GAS',
      image: gas,
    },
    {
      name: 'SH',
      image: SH,
    },
    {
      name: 'Kripton',
      image: kripton,
    },
    {
      name: 'Dtrans',
      image: Dtrans,
    },
    {
      name: 'BTFatma',
      image: BTFatma,
    },
    {
      name: 'carolin',
      image: carolin,
    },
    {
      name: 'clairEssential',
      image: clairEssential,
    },
    {
      name: 'DKU',
      image: DKU,
    },
    {
      name: 'dtp',
      image: dtp,
    },
    {
      name: 'gudegMbakYus',
      image: gudegMbakYus,
    },
    {
      name: 'monkey',
      image: monkey,
    },
    {
      name: 'teradisional',
      image: teradisional,
    },
  ];

  const faqList = [
    {
      questionId: 'Rekadigi.id bergerak dibidang apa?',
      answerId:
        'Rekadigi.id adalah perusahaan agensi yang bergerak di bidang Digital Marketing.',
      questionEn: 'What field is Rekadigi.id engaged in?',
      answerEn:
        'Rekadigi.id is a company engaged in Information Technology, and Digital Marketing.',
    },
    {
      questionId: 'Apa saja yang dilakukan oleh Rekadigi.id?',
      answerId:
        'Kami melayani jasa pembuatan Social Media Content, Company Profile Video, Audio Visual Product, One Stop Content, dan Logo Design',
      questionEn: 'What does Rekadigi.id do?.',
      answerEn:
        'We provide services for making Mobile and Web Applications, UI/UX, Websites, Company Profiles, Audio Visual Products, Social Media Management.',
    },
    {
      questionId: 'Bagaimana jika ingin bertanya-tanya terlebih dahulu?',
      answerId:
        'Kami melayani konsultasi gratis agar dapat menyesuaikan keinginan dan kebutuhan klien. Kami juga mengedepankan kenyamanan serta tercapainya tujuan bersama dengan klien.',
      questionEn: 'What if you want to ask questions first?',
      answerEn:
        'We provide free consultations in order to adjust to the wishes and needs of clients. We also prioritize comfort and achieve common goals with clients.',
    },
    {
      questionId:
        'Bagaimana cara menghubungi perusahaan jika pertanyaan saya tidak terjawab di sini?',
      answerId:
        'Silahkan untuk menghubungi melalui kontak yang tertera di halaman resmi kami. Atau Anda dapat menghubungi kami via email ke: digital.marketing@rekadigi.id',
      questionEn:
        'How do I contact the company if my question is not answered here?',
      answerEn:
        'Please contact via the contact listed on our official page. Or you can contact us via email to: digital.marketing@rekadigi.id',
    },
    {
      questionId: 'Apakah harga yang dicantumkan dapat berubah?',
      answerId:
        'Harga yang kami cantumkan sudah diperhitungkan dengan sebaik mungkin. Tetapi harga dapat disesuaikan dengan kebutuhan klien itu sendiri.',
      questionEn: 'Are the prices listed subject to change?',
      answerEn:
        'The prices we list have been calculated as best as possible. But the price can be adjusted according to the needs of the client itself.',
    },
    {
      questionId: 'Dimana lokasi Rekadigi.id?',
      answerId:
        'Alamat kami berada di Jl. Wijaya Kusuma No.39, Tipes, Kec. Serengan, Kota Surakarta, Jawa Tengah 57154.',
      questionEn: 'Where is the location of Rekadigi.id?',
      answerEn:
        'Our address is at St. Wijaya Kusuma No.39, Tipes, Serengan, Surakarta City, Central Java 57154.',
    },
  ];

  // Language state
  const [idLanguage, setIdLanguage] = useState(true);

  // handle sidebar
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  // handle blur background effect for header component
  const [headerBackground, setHeaderBackground] = useState('bg-white');
  const handleHeaderBackground = () => {
    if (window.scrollY === 0) {
      setHeaderBackground('bg-white');
    } else {
      setHeaderBackground('bg-white/60 blur-12');
    }
  };
  window.addEventListener('scroll', handleHeaderBackground);

  // swipe animation form tim member section
  const [visibleIndex, setVisibleIndex] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setVisibleIndex((prevIndex) => (prevIndex + 2) % teamMembers.length);
    }, 2500);

    return () => {
      clearInterval(timer);
    };
  }, [teamMembers.length]);

  // handle send email feature
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_o77xznb',
        'template_7q26sma',
        form.current,
        'ExaKY-JsUkmQyYooB'
      )
      .then(
        (result) => {
          e.target.reset();
          setShowSentNotif(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setTimeout(() => {
      setShowSentNotif(false);
    }, 3000);
  };

  return (
    <div className="relative">
      <div id="top" className="overflow-x-hidden bg-black/50 -z-[999]">
        {/* Header */}
        <header
          className={`fixed top-0 z-50 w-full shadow-sm ${headerBackground} px-4 lg:px-8 xl:px-16 3xl:px-28 4xl:px-36`}
        >
          <nav className="h-14 md:h-20 2xl:h-[120px] flex justify-between items-center overflow-y-hidden">
            <figure className="w-auto 2xl:w-[20%]">
              <Link
                smooth={true}
                spy={true}
                to="top"
                className="cursor-pointer"
              >
                <img
                  src={logo}
                  alt="logo"
                  className="w-8 h-8 md:h-12 md:w-12 lg:h-14 lg:w-14"
                />
              </Link>
            </figure>
            <div className="w-[350px] lg:w-[400px] 2xl:w-[35%] hidden md:block ">
              {/* navbar for laptop */}
              <ul className="flex justify-between text-lg font-medium 2xl:hidden 2xl:text-xl items center text-charcoal">
                <li>
                  <Link
                    smooth={true}
                    spy={true}
                    offset={-60}
                    to="layanan"
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Layanan' : 'Service'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="portfolio"
                    smooth={true}
                    spy={true}
                    offset={-70}
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Portofolio' : 'Portfolio'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="karir"
                    smooth={true}
                    spy={true}
                    offset={-50}
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Karir' : 'Career'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="galeri"
                    smooth={true}
                    spy={true}
                    offset={-30}
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Galeri' : 'Gallery'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="faq"
                    smooth={true}
                    spy={true}
                    offset={-40}
                    className="cursor-pointer"
                  >
                    FaQ
                  </Link>
                </li>
              </ul>
              {/* navbar for desktop */}
              <ul className="justify-between hidden text-lg font-medium 2xl:flex 2xl:text-xl 5xl:text-3xl items center text-charcoal">
                <li>
                  <Link
                    smooth={true}
                    spy={true}
                    offset={-120}
                    to="layanan"
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Layanan' : 'Service'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="portfolio"
                    smooth={true}
                    spy={true}
                    offset={-120}
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Portofolio' : 'Portfolio'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="karir"
                    smooth={true}
                    spy={true}
                    offset={-120}
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Karir' : 'Career'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="galeri"
                    smooth={true}
                    spy={true}
                    offset={-120}
                    className="cursor-pointer"
                  >
                    {idLanguage ? 'Galeri' : 'Gallery'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="faq"
                    smooth={true}
                    spy={true}
                    offset={-100}
                    className="cursor-pointer"
                  >
                    FaQ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="md:flex items-center w-[280px] 2xl:w-[28%] 3xl:w-[25%] 5xl:w-[18%] justify-between hidden">
              <a
                href="http://wa.me/+62816622050"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 text-base font-medium text-white rounded-md 2xl:rounded-lg 2xl:text-xl 2xl:py-4 bg-navy-blue 2xl:px-9"
              >
                {idLanguage ? 'Hubungi Kami' : 'Contact Us'}
              </a>
              <button
                className="flex h-11 2xl:h-[59px] w-28 rounded-3xl 2xl:w-[136px] items-center 2xl:rounded-[56px] justify-center gap-8 2xl:gap-4 shadow-sm"
                onClick={() => setIdLanguage(!idLanguage)}
              >
                <img
                  src={idLanguage ? idFlag : enFlag}
                  alt="language"
                  className="w-8 h-8 rounded-full 2xl:h-11 2xl:w-11"
                />
                <span className="text-base font-semibold">
                  {idLanguage ? 'ID' : 'EN'}
                </span>
              </button>
            </div>
            {/* humberger button  */}
            <button
              type="button"
              onClick={toggleSidebar}
              className="block btn btn-circle btn-sm md:hidden"
            >
              <svg
                className="fill-current swap-off "
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 512 512"
              >
                <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
              </svg>
            </button>
          </nav>
        </header>

        {/* sidebar for mobile/tablet */}
        <div className="z-50 block md:hidden">
          <div className="flex items-center justify-between"></div>
          <div
            className={`z-[999] fixed top-0 right-0 h-full px-2 xl:px-0 w-[250px] border-l-[1px] border-gray-lighter/40 bg-white text-gray-800 transition-all duration-300 overflow-auto ${
              showSidebar ? 'translate-x-0' : 'translate-x-full'
            } xl:translate-x-0 xl:block xl:static`}
          >
            {/* button close */}
            <div className="flex justify-between mt-2">
              <button
                onClick={toggleSidebar}
                type="button"
                className="px-3 -mr-2 duration-300 transform xl:hidden btn-sm hover:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <button
                className="flex items-center justify-center w-24 gap-2 shadow-sm h-11 rounded-3xl"
                onClick={() => setIdLanguage(!idLanguage)}
              >
                <img
                  src={idLanguage ? idFlag : enFlag}
                  alt="language"
                  className="w-8 rounded-full"
                />
                <span className="ml-4 text-base font-semibold">
                  {idLanguage ? 'ID' : 'EN'}
                </span>
              </button>
            </div>
            {/* nav menu */}
            <nav className="mt-6">
              <div className="w-[35%] ml-2">
                <ul className="flex flex-col gap-2 text-base font-medium text-charcoal">
                  <li>
                    <Link
                      smooth={true}
                      spy={true}
                      offset={-50}
                      to="layanan"
                      className="cursor-pointer"
                    >
                      {idLanguage ? 'Layanan' : 'Service'}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="portfolio"
                      smooth={true}
                      spy={true}
                      offset={-40}
                      className="cursor-pointer"
                    >
                      {idLanguage ? 'Portofolio' : 'Portfolio'}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="karir"
                      smooth={true}
                      spy={true}
                      offset={-40}
                      className="cursor-pointer"
                    >
                      {idLanguage ? 'Karir' : 'Career'}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="galeri"
                      smooth={true}
                      spy={true}
                      offset={-10}
                      className="cursor-pointer"
                    >
                      {idLanguage ? 'Galeri' : 'Gallery'}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="faq"
                      smooth={true}
                      spy={true}
                      offset={-10}
                      className="cursor-pointer"
                    >
                      FaQ
                    </Link>
                  </li>
                </ul>
              </div>
              <a
                href="http://wa.me/+62816622050"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-full py-2 mx-2 mt-5 text-base font-medium text-center text-white rounded-lg px-7 bg-navy-blue">
                  {idLanguage ? 'Hubungi Kami' : 'Contact Us'}
                </div>
              </a>
            </nav>
          </div>
        </div>

        {/* jumbotron */}
        <div className="relative h-40 md:h-[400px] lg:h-[500px] xs:h-56 sm:h-80 2xl:h-[700px] 4xl:h-[1103px] mt-14  bg-white z-20">
          <div className="flex items-center justify-between  leading-none 4xl:leading-[117px] pl-4 xs:pl-10 sm:pl-20 2xl:pl-44 4xl:pl-36 5xl:pl-52 h-full">
            <p className="font-bold text-sm xs:text-lg sm:text-2xl md:text-3xl lg:text-4xl sm:w-80 md:w-96 xl:text-6xl 2xl:text-7xl 3xl:text-7xl 4xl:text-7xl 5xl:text-9xl  md:leading-[40px] w-[160px] xs:w-[240px] 3xl:w-[950px] 4xl:w-[966px] lg:w-[450px] xl:w-[800px] 2xl:w-[800px] 5xl:w-[1400px] lg:leading-[60px] xl:leading-[70px] 2xl:leading-[80px] 3xl:leading-[90px] 4xl:leading-[100px] 5xl:leading-[160px]">
              {idLanguage
                ? 'Pengalaman Digital Lebih Baik Dengan '
                : 'Better Digital Experience With '}
              <span className="text-navy-blue">Rekadigi.id</span>
            </p>
            <img
              src={jumbotronImage}
              alt="jumbotron"
              className="w-32 min-[350px]:w-40 sm:w-64 md:w-80  4xl:w-[835px] lg:w-[450px] 3xl:w-[700px] 2xl:w-[600px]"
            />
            <div className="bg-white absolute w-60 xs:w-80 h-36 xs:h-48 2xl:w-[1235px] 2xl:h-[600px] 4xl:h-[821px] rotate-[18deg] sm:w-[500px] sm:h-64 left-40 min-[350px]:left-44 xs:left-[300px] sm:left-[380px] lg:left-[500px] 2xl:left-[700px] 3xl:left-[870px] 5xl:left-[1700px] rounded-lg sm:rounded-2xl -z-30 shadow-xs md:w-[700px] lg:w-[1000px] lg:h-[500px] md:h-80 md:rounded-3xl lg:rounded-[56px]" />
            <div className="bg-white sm:w-[500px] sm:h-64 absolute w-60 xs:w-80 h-36 xs:h-48 2xl:h-[600px]  2xl:w-[1235px] sm:rounded-2xl min-[350px]:-left-24 xs:-left-14 4xl:h-[821px] rotate-[18deg] -left-28 sm:-left-48 4xl:-left-[620px] 5xl:-left-[0px] -top-12 min-[350px]:-top-6 xs:-top-1 4xl:-top-[22px] 5xl:top-28 rounded-lg lg:w-[1000px] lg:h-[500px] lg:rounded-[56px] -z-30 md:w-[700px] md:h-80 md:rounded-3xl shadow-xs md:-left-96 lg:-left-[600px] lg:-top-32" />
          </div>
        </div>

        {/* Visi & Misi */}
        <div className="relative z-20 bg-white-lilac">
          <div className="px-2 xs:px-4 sm:px-8 md:px-16 lg:px-24 2xl:px-28 3xl:px-36 py-6 xl:py-16 md:py-10 2xl:py-[89px]">
            <div>
              <p className="mb-2 2xl:mb-4 3xl:mb-[48px] font-bold text-[10px] xs:text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-2xl text-gray-battleship">
                {idLanguage
                  ? 'Rekadigi.id adalah perusahaan jasa di bidang Digital Marketing'
                  : 'Rekadigi.id is a service company in the field of Digital Marketing'}
              </p>
              {idLanguage ? (
                <p className="font-bold w-[95%] xs:w-[85%] sm:w-[90%] text-base sm:text-2xl lg:text-3xl xl:text-4xl  2xl:text-5xl 3xl:text-6xl 4xl:text-[80px] leading-5 xl:leading-[40px] 2xl:leading-[60px] 3xl:leading-[70px] 4xl:leading-[98px]">
                  <span className="text-navy-blue">
                    Transformasikan Bisnis{' '}
                  </span>
                  Anda Melalui{' '}
                  <span className="text-navy-blue">Digital Marketing</span> yang
                  Komprehensif dan Inovatif untuk Membangun Citra Perusahaan
                </p>
              ) : (
                <p className="font-bold w-[95%] xs:w-[85%] sm:w-[90%] text-base sm:text-2xl lg:text-3xl xl:text-4xl  2xl:text-5xl 3xl:text-6xl 4xl:text-[80px] leading-5 xl:leading-[40px] 2xl:leading-[60px] 3xl:leading-[70px] 4xl:leading-[98px]">
                  <span className="text-navy-blue">
                    Transform Your Business
                  </span>{' '}
                  Through Comprehensive and Innovative
                  <span className="text-navy-blue"> Digital Marketing </span>
                  to Build a Strong Company Image
                </p>
              )}
            </div>
            <div className="flex flex-col md:flex-row mt-5 2xl:mt-[61px] gap-0 md:gap-10">
              <div className="z-30 w-full 2xl:w-1/2 ">
                <h2 className="font-bold text-sm lg:text-base xl:text-lg 2xl:text-[32px] text-[#222222]">
                  {idLanguage ? 'Visi' : 'Vision'}
                </h2>
                <p className="mt-1 text-xs font-semibold 2xl:mt-4 lg:text-sm xl:text-base 2xl:text-xl text-gray-battleship">
                  {idLanguage
                    ? 'Penyedia layanan Digital Marketing yang inovatif dan berkualitas tinggi'
                    : 'To be an innovative provider and high-quality technology development services in various industries.'}
                </p>
              </div>
              <div className="z-30 w-full mt-2 2xl:w-1/2 2xl:mt-0">
                <h2 className="font-bold text-sm lg:text-base xl:text-lg 2xl:text-[32px] text-[#222222]">
                  {idLanguage ? 'Misi' : 'Mission'}
                </h2>
                <ul className="ml-4 text-xs font-semibold list-disc list-outside lg:ml-5 2xl:ml-8 lg:text-sm xl:text-base 2xl:text-xl text-gray-battleship">
                  <li className="mt-1 2xl:mt-4">
                    {idLanguage
                      ? 'Memberikan solusi digital marketing berkualitas tinggi untuk klien.'
                      : 'Providing high-quality digital marketing solutions for clients.'}
                  </li>
                  <li className="mt-1 2xl:mt-4">
                    {idLanguage
                      ? 'Menciptakan produk digital yang unggul dan efisien.'
                      : 'Creating outstanding and efficient digital products.'}
                  </li>
                  <li className="mt-1 2xl:mt-4">
                    {idLanguage
                      ? 'Membangun kolaborasi sukses dengan klien.'
                      : 'Building successful collaborations with clients.'}
                  </li>
                  <li className="mt-1 2xl:mt-4">
                    {idLanguage
                      ? 'Memberikan pengalaman terbaik kepada pelanggan.'
                      : 'Providing the best experience to customers.'}
                  </li>
                </ul>
              </div>
            </div>
            <div className="absolute -top-5 2xl:-top-20 -right-20 2xl:-right-14 -z-30 w-[100px] sm:w-[150px] xl:w-[200px] 2xl:w-[354px] h-[100px] sm:h-[150px] xl:h-[200px] 2xl:h-[354px]  rounded-full bg-white shadow-xs" />
            <div className="absolute -bottom-10 2xl:-bottom-32 -left-20 -z-30 w-[100px] sm:w-[150px] xl:w-[200px] 2xl:w-[354px] h-[100px] sm:h-[150px] xl:h-[200px] 2xl:h-[354px]  rounded-full bg-white shadow-xs" />
          </div>
        </div>

        {/* Tim Kami */}
        <div className="relative z-[15] bg-white">
          <div className="relative z-30 py-16 pl-2 md:py-24 xl:py-20 2xl:py-36 xs:pl-4 sm:pl-8 md:pl-16 lg:pl-24 xl:pl-28 3xl:pl-36">
            <div className="w-[95%] xs:w-[90%] sm:w-4/5 md:w-3/5 xl:w-3/5">
              <h2 className="mb-3 text-xl font-bold lg:text-2xl xl:text-3xl 2xl:text-5xl sm:mb-6">
                {idLanguage ? 'Tim Kami' : 'Our Team'}
              </h2>
              <p className="text-xs font-semibold lg:text-base 3xl:text-xl text-gray-battleship">
                {idLanguage
                  ? 'Perusahaan kami mengedepankan budaya kerja yang menjunjung tinggi kebebasan dalam berkreasi, saling toleransi dalam keragaman, keterbukaan berpendapat sehingga tercipta lingkungan kerja yang menyenangkan, fleksibel tetapi tujuan tetap tercapai.'
                  : 'Our company promotes a work culture that upholds freedom for creativity, mutual tolerance for diversity, and openness of opinion to create a fun environment, and flexible work environment while still achieving goals.'}
              </p>
            </div>
            <div className="mt-10 2xl:mt-[104px] w-[90%] sm:w-3/5 lg:w-[664px] xl:w-[800px] flex justify-between mx-auto h-36 md:h-48 lg:h-48 xl:h-52 3xl:h-[360px]">
              {teamMembers.map((teamMember, index) => (
                <div
                  className={`w-[300px] xl:w-96 transition-all  duration-500 ${
                    index === visibleIndex ||
                    index === visibleIndex + 1 ||
                    index === visibleIndex - 2
                      ? 'translate-x-0'
                      : 'translate-x-full fixed right-0 -bottom-[10000px]'
                  } `}
                  key={index}
                >
                  <figure>
                    <img
                      src={teamMember.photo}
                      alt={teamMember.name}
                      className="h-24 md:h-36 3xl:h-[300px] rounded-full border-4 xl:border-[6px] border-navy-blue mx-auto"
                    />
                  </figure>
                  <div>
                    <h2 className="text-[10px] sm:text-xs md:text-base font-semibold text-center lg:text-lg xl:text-2xl`">
                      {teamMember.name}
                    </h2>
                    <p className="text-[10px] font-medium text-center md:text-xs lg:text-sm xl:text-xl text-gray-battleship">
                      {teamMember.position}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white absolute w-60 xs:w-80 h-36 xs:h-48 2xl:w-[1235px] 2xl:h-[600px] 4xl:h-[821px] rotate-[18deg] sm:w-[500px] sm:h-64 -right-28 sm:-right-72 lg:-right-[800px] 2xl:-right-[1000px] 3xl:left-[1500px] top-10 xs:top-24 lg:top-40 3xl:top-[160px] rounded-lg sm:rounded-2xl -z-30 shadow-xs md:w-[700px] lg:w-[1000px] lg:h-[500px] md:h-80 md:rounded-3xl lg:rounded-[56px]" />
          <div className="bg-white absolute sm:w-[500px] sm:h-64  w-60 xs:w-80 h-36 xs:h-48 2xl:h-[600px] 2xl:w-[1235px] sm:rounded-2xl 4xl:h-[821px] rotate-[18deg] -left-28 sm:-left-72 lg:-left-[800px] 2xl:-left-[1000px] 4xl:-left-[620px] top-72 xs:top-60 sm:top-40 lg:top-10 3xl:top-52 4xl:-top-[22px] rounded-lg lg:w-[1000px] lg:h-[500px] lg:rounded-[56px] -z-30 md:w-[700px] md:h-80 md:rounded-3xl shadow-xs md:-left-96" />
        </div>

        {/* layanan kami */}
        <div
          id="layanan"
          className="relative z-[11] bg-white-lilac py-16 md:py-24 xl:py-20 2xl:py-36"
        >
          <div className="pl-4 sm:pl-8 md:pl-16 lg:pl-24 xl:pl-28 3xl:pl-36 w-[95%] xs:w-[90%] sm:w-4/5 md:w-3/5 xl:w-3/5 2xl:w-[55%]">
            <h2 className="mb-3 text-xl font-bold lg:text-2xl xl:text-5xl sm:mb-6">
              {idLanguage ? 'Layanan Kami' : 'Our Service'}
            </h2>
            <p className="text-xs font-semibold lg:text-base 3xl:text-xl text-gray-battleship">
              {idLanguage
                ? 'Kami hadir untuk menjadi mitra bisnis anda dengan menawarkan solusi kreatif dan inovatif dalam pengembangan IT dan pemasaran digital.'
                : 'We are here to be your business partner by offering creative and innovative solutions in IT development and digital marketing.'}
            </p>
          </div>
          <div className="flex flex-wrap justify-center sm:justify-between xl:justify-center mt-10 2xl:mt-20  w-[95%] xs:w-full sm:w-[620px] md:w-[680px] lg:w-[800px] xl:w-[1200px] 2xl:w-[1300px] 3xl:w-[1450px] 4xl:w-[1700px] mx-auto gap-4 md:gap-7 4xl:gap-8">
            {serviceData.map((service, index) => (
              <ServiceInfoCard
                key={index}
                serviceImage={service.serviceImage}
                serviceTitle={
                  idLanguage
                    ? service.serviceTitle
                    : service.serviceTitleEnglish
                }
                serviceDescription={
                  idLanguage
                    ? service.serviceDescription
                    : service.serviceDescriptionEnglish
                }
              />
            ))}
          </div>
          <img
            src={blobShape}
            alt="blopshape"
            className="absolute bottom-0 3xl:bottom-[280px] -left-5 lg:-left-28 3xl:-left-[170px] -z-30 w-56 xl:w-80 3xl:w-[400px]"
          />
          <img
            src={blobShape}
            alt="blopshape"
            className="absolute top-[170px] -right-32 xl:-right-[190px] -z-30 w-56 xl:w-80 3xl:w-[400px]"
          />
        </div>

        {/* Client */}
        <div
          id="portfolio"
          className="relative z-20 py-10 bg-white xl:py-16 2xl:py-20"
        >
          <h2 className="font-medium text-base sm:text-xl xl:text-2xl mb-[76px] text-center">
            {idLanguage ? 'Sudah dipercayai oleh' : 'Already trusted by'}
          </h2>
          <ClientsSlider clientsData={clientsData} />
        </div>

        {/* Bagaimana Kami Bekerja */}
        <div className="relative z-20 py-8 pl-4 md:py-16 lg:py-24 xl:py-28 3xl:py-36 bg-white-lilac sm:pl-8 md:pl-16 lg:px-24 xl:px-28 3xl:px-36">
          <h2 className="mb-5 text-lg font-bold sm:mb-10 xs:text-xl lg:text-2xl xl:text-3xl 2xl:text-5xl">
            {idLanguage ? 'Bagaimana Kami Bekerja' : 'How We Work'}
          </h2>
          <div className="flex pr-[1px] sm:pr-0 justify-between">
            <div className="w-auto sm:w-[600px] md:w-[700px] lg:w-[50%] 4xl:w-[41%]">
              <div className="flex gap-2 sm:gap-4 xl:gap-9">
                <div>
                  <div className="flex items-center justify-center w-8 h-8 rounded-full xl:w-14 xl:h-14 bg-navy-blue">
                    <span className="text-lg font-semibold text-white rounded-ful xl:text-2xl">
                      1
                    </span>
                  </div>
                </div>
                <p className="text-xs font-normal sm:text-sm lg:text-base 2xl:text-xl 3xl:text-2xl">
                  {idLanguage
                    ? 'Melakukan diskusi dengan klien terkait konsep dan anggaran. Brainstorming Ide dan menyusun rancangan desain yang terkait dengan penelitian untuk diperiksa kelayakannya.'
                    : 'Conduct discussions with clients regarding concepts and budgets. Brainstorming ideas and drafting designs related to research to check their feasibility.'}
                </p>
              </div>
              <div className="flex gap-2 mt-3 lg:mt-5 sm:gap-4 xl:gap-9 3xl:mt-14">
                <div>
                  <div className="flex items-center justify-center w-8 h-8 rounded-full xl:w-14 xl:h-14 bg-navy-blue">
                    <span className="text-lg font-semibold text-white xl:text-2xl">
                      2
                    </span>
                  </div>
                </div>
                <p className="text-xs font-normal sm:text-sm lg:text-base 2xl:text-xl 3xl:text-2xl">
                  {idLanguage
                    ? 'Setelah menyelesaikan uji kelayakan, rancangan desain akhir disiapkan dengan menggunakan smart tools. Kemudian, tim pengembangan kami mulai mengembangkan solusi yang sesuai dengan desain. Dalam tahap pengujian, kami menguji setiap komponen untuk memastikan bahwa solusi kami sesuai dengan kebutuhan.'
                    : 'After completing the due diligence, the final design draft was prepared using advanced tools. Then, our development team started to develop a solution according to the design. In the testing phase, we test each component to ensure that the solution meets our needs.'}
                </p>
              </div>
              <div className="flex gap-2 mt-3 lg:mt-5 sm:gap-4 xl:gap-9 3xl:mt-14">
                <div>
                  <div className="flex items-center justify-center w-8 h-8 rounded-full xl:w-14 xl:h-14 bg-navy-blue">
                    <span className="text-lg font-semibold text-white rounded-ful xl:text-2xl">
                      3
                    </span>
                  </div>
                </div>
                <p className="text-xs font-normal sm:text-sm lg:text-base 2xl:text-xl 3xl:text-2xl">
                  {idLanguage
                    ? 'Kami memberikan beberapa pelayanan gratis untuk membantu klien mengelola dan mengontrol solusi, seperti konsultasi gratis dan memberikan gratis akses System Management Controlling selama proses pembuatan.'
                    : 'We provide several free services to help clients manage and control solutions, such as free consultations and providing free System Management Controlling access during the build process.'}
                </p>
              </div>
            </div>
            <figure className="hidden lg:block">
              <img
                src={work}
                alt="work"
                className="h-[350px] w-[450px] 2xl:h-[500px] 2xl:w-[675px]"
              />
            </figure>
          </div>
          <img
            src={blobShape}
            alt="blopshape"
            className="absolute h-[200px] w-[200px] lg:w-[400px] lg:h-[400px] top-0 sm:top-11 -right-36 sm:-right-28 md:-right-14 lg:top-[180px] lg:right-[55px] -z-30 "
          />
          <img
            src={blobShape}
            alt="blopshape"
            className="absolute h-[200px] w-[200px] lg:w-[400px] lg:h-[400px] top-16 sm:top-24 lg:top-[150px] -left-36 md:-left-20 lg:-left-[270px] -z-30 "
          />
        </div>

        {/* Karir */}
        <div id="karir" className="relative z-20 py-4 bg-white xs:py-8 lg:py-0">
          <div className="flex w-full lg:w-[70%] xl:w-3/5 2xl:w-[67%] ml-2 xs:ml-[10%] sm:ml-[15%] lg:ml-36 xl:ml-[279px] 5xl:ml-[370px] justify-between items-center">
            <div>
              <h2 className="font-bold text-base xs:text-lg lg:text-xl xl:text-2xl 2xl:text-[32px]">
                {idLanguage ? 'Karir' : 'Career'}
              </h2>
              <p className="my-2 text-xs font-medium 2xl:my-6 lg:text-base 3xl:text-xl ">
                Open Position{' '}
                <span className="font-bold">
                  Developer, Designeer and Internship
                </span>
              </p>
              <button className="px-4 py-2 text-xs font-medium text-white rounded-md lg:text-base 3xl:text-xl 2xl:rounded-lg 2xl:px-8 2xl:py-4 bg-navy-blue">
                <a
                  href="https://drive.google.com/drive/folders/1EFmTuPDRZ0-QWQF5ZZO0Msani2DbHJvT?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {idLanguage ? 'Gabung Sekarang' : 'Join Now'}
                </a>
              </button>
            </div>
            <figure className="hidden lg:block">
              <img
                src={look}
                alt="Look"
                className="xl:h-72 xl:w-52 2xl:w-[369px] h-52 w-40 2xl:h-[408px]"
              />
            </figure>
          </div>
        </div>

        {/* Get Project*/}
        <div className="bg-white-lilac lg:py-[22px] relative z-20 py-4 xs:py-8">
          <div
            className="flex
          w-full md:w-3/4 lg:w-3/5  pl-2 xs:pl-[10%] sm:pl-[15%] lg:pl-0 ml-0 lg:ml-44 5xl:ml-[550px] xl:w-7/12 xl:ml-[344px] justify-between items-center"
          >
            <figure className="hidden lg:block">
              <img
                src={device}
                alt="Device"
                className="w-96 h-48 2xl:w-[514px] 2xl:h-[325px]"
              />
            </figure>
            <div className="w-full 2xl:w-[45%]">
              <h2 className="font-bold text-base xs:text-lg lg:text-xl xl:text-2xl 2xl:text-[32px]">
                {idLanguage ? 'Mulai Projekmu' : 'Start your project'}
              </h2>
              <p className="my-2 text-xs font-medium 2xl:my-6 lg:text-base 3xl:text-xl text-gray-battleship">
                {idLanguage
                  ? 'Jelajahi beragam produk dan layanan yang lengkap, ungkapkan potensimu, dan mulailah petualangan baru.'
                  : 'Explore a diverse range of complete products and services, express your potential, and embark on a new adventure.'}
              </p>
              <button className="px-4 py-2 text-xs font-medium text-white rounded-md lg:text-base 3xl:text-xl 2xl:rounded-lg 2xl:px-8 2xl:py-4 bg-navy-blue">
                <a
                  href="http://wa.me/+62816622050"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {idLanguage ? 'Mulai Projek' : 'Start Project'}
                </a>
              </button>
            </div>
          </div>
        </div>

        {/* Our Team */}
        <div id="galeri" className="relative z-20 pt-20 bg-white">
          <h2 className="mb-10 text-xl font-bold text-center lg:mb-20 2xl:mb-40 lg:text-2xl xl:text-5xl">
            {idLanguage
              ? 'Perkenalkan Tim Rekadigi.id'
              : 'Introducing Rekadigi.id Team'}
          </h2>
          <div className="flex flex-wrap justify-center mx-auto w-auto lg:w-[800px] 2xl:w-[1200px] 3xl:w-auto">
            <img
              src={teamPhoto1}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto2}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto3}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto4}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto5}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto6}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto7}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto8}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto9}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto10}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto11}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
            <img
              src={teamPhoto12}
              alt="Team"
              className="duration-500 cursor-pointer grayscale hover:grayscale-0  w-64 h-40 2xl:w-96 2xl:h-60 3xl:w-[544px] 3xl:h-[362.71px]"
            />
          </div>
        </div>

        {/* FAQ */}
        <div
          id="faq"
          className="relative pb-8 sm:pb-16 lg:pb-20 bg-white pt-8 sm:pt-16 lg:pt-20  2xl:pt-36 mb-[400px] 3xl:mb-[583px] z-20 "
        >
          <div className="flex flex-col gap-4 ml-0 md:gap-8 lg:gap-16 2xl:gap-48 lg:flex-row lg:ml-24 xl:ml-28 3xl:ml-36">
            <div className="w-auto lg:w-[40%] 2xl:w-[40%] ml-1 xs:ml-[3%] sm:ml-[8%] lg:ml-0">
              <img
                src={abstractElips}
                alt="Abstract Elips"
                className="w-10 h-10 md:w-14 md:h-14 2xl:w-[150px] 2xl:h-[150px]"
              />
              <h2 className="mt-3 mb-1 text-lg font-bold 2xl:mb-6 sm:text-xl lg:text-xl 2xl:text-5xl 2xl:mt-14">
                Frequently <br /> Asked Questions
              </h2>
              <p className="text-xs font-medium lg:text-base 3xl:text-xl">
                {idLanguage
                  ? 'Masih membutuhkan bantuan? '
                  : 'Still need help? '}
                <a
                  href="http://wa.me/+62816622050"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold underline"
                >
                  {idLanguage ? 'Hubungi Kami' : 'Chat Us'}
                </a>
              </p>
            </div>
            <div className="flex flex-col gap-y-4 2xl:gap-y-8 w-auto mx-2 xs:mx-[5%] sm:mx-[12%] lg:ml-0 lg:w-3/5 2xl:w-[57%]">
              {faqList.map((faq, index) => (
                <div
                  key={index}
                  className="inline p-3 rounded-lg lg:p-4 2xl:p-8 2xl:rounded-3xl bg-neutral-50"
                >
                  <p className="mb-2 text-sm font-bold 2xl:mb-6 2xl:text-base 4xl:text-2xl">
                    {idLanguage ? faq.questionId : faq.questionEn}
                  </p>
                  <p className="text-xs font-medium 2xl:text-sm 4xl:text-xl text-gray-battleship">
                    {idLanguage ? faq.answerId : faq.answerEn}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white absolute  w-[1235px] h-[450px] 2xl:h-[821px] rotate-[18deg] -left-[760px] rounded-[56px] -z-50 shadow-xs top-[200px]" />
        </div>

        {/* Footer */}
        <div className="fixed bottom-0 left-0 right-0 z-10 w-full h-[400px] 3xl:h-[583px] overflow-y-auto bg-navy-blue pt-4 2xl:pt-[51px]">
          <div className="relative">
            <div className="mt-6 flex flex-col-reverse md:flex-row  justify-between items-start mx-auto z-[999] w-[95%] sm:w-[90%] lg:w-[85%] 4xl:w-[1635px]">
              <div className="mt-4 md:mt-0 w-full md:w-[48%] z-[999]">
                <div className="mb-2 text-white md:mb-0">
                  <h2 className="font-bold text-xl md:text 2xl:text-[32px]">
                    {idLanguage ? 'Kontak Kami' : 'Contact Us'}
                  </h2>
                  <div className="flex my-4 gap-x-2">
                    <img src={email} alt="email" />
                    <span className="text-xs font-medium lg:text-sm 3xl:text-xl">
                      Email: digital.marketing@rekadigi.id
                    </span>
                  </div>
                  <div className="flex gap-x-2">
                    <img src={telephone} alt="telephone" />
                    <span className="text-xs font-medium lg:text-sm 3xl:text-xl">
                      Telepon: +62 816-622-050
                    </span>
                  </div>
                </div>
                <figure className="mt-10">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-10 h-10 md:w-14 md:h-14"
                  />
                </figure>
                <div className="mt-2">
                  <h2 className="text-xl md:text-2xl 2xl:text-[32px] font-bold text-white ">
                    {idLanguage ? 'Temukan Kami' : 'Find Us'}
                  </h2>
                  <p className="my-1 text-xs font-medium text-white md:my-2 lg:text-sm 3xl:text-xl">
                    Jl. Wijaya Kusuma No.39, Tipes, Kec. Serengan, Kota
                    Surakarta, Jawa Tengah 57154
                  </p>
                  <div className="flex items-center gap-2 lg:gap-6">
                    <a
                      href="http://wa.me/+62816622050"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={whatsApp}
                        alt="Whatsapp"
                        className="w-8 h-8 md:h-10 md:w-10 2xl:h-[42px] 2xl:w-[42px]"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/rekadigi.id/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={whiteInstagram}
                        alt="Instagram"
                        className="w-8 h-8 md:h-10 md:w-10 2xl:h-[42px] 2xl:w-[42px]"
                      />
                    </a>

                    <a
                      href="https://www.facebook.com/dayarekadigital/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={facebook}
                        alt="facebook"
                        className="w-8 h-8 md:h-10 md:w-10 2xl:h-[42px] 2xl:w-[42px]"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/pt-daya-rekadigital-indonesia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={linkedin}
                        alt="linkedin"
                        className="w-7 h-7 2xl:h-8 2xl:w-8"
                      />
                    </a>
                  </div>
                  <div className="mt-5">
                    <p className="text-xs text-white lg:text-xs 3xl:text-base">
                      &copy; 2023 Daya Rekadigital Indonesia
                    </p>
                  </div>
                </div>
              </div>
              <div className="font-normal text-white w-full md:w-[48%] z-20">
                <form ref={form} onSubmit={sendEmail}>
                  <div>
                    <label
                      htmlFor="name"
                      className="cursor-pointer font-bold text-xl md:text 2xl:text-[32px] z-[999]"
                    >
                      {idLanguage ? 'Nama' : 'Name'}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="user_name"
                      required
                      placeholder={
                        idLanguage ? 'Masukkan nama anda' : 'Input your name'
                      }
                      className="w-full px-4 py-3 3xl:px-8 3xl:py-4 mt-1 mb-3 md:my-6 text-black rounded-md md:rounded-lg text-xs lg:text-sm 3xl:text-xl placeholder:text-gray-battleship z-[999]"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="cursor-pointer font-bold text-xl md:text 2xl:text-[32px] z-[999]"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="user_email"
                      required
                      placeholder={
                        idLanguage ? 'Masukkan email anda' : 'Input your email'
                      }
                      className="w-full px-4 py-3 3xl:px-8 3xl:py-4 mt-1 mb-3 md:my-6 text-black rounded-md md:rounded-lg text-xs lg:text-sm 3xl:text-xl placeholder:text-gray-battleship z-[999]"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="message"
                      className="cursor-pointer font-bold text-xl md:text-2xl:text-[32px] z-[999]"
                    >
                      {idLanguage ? 'Pesan' : 'Message'}
                    </label>
                    <br />
                    <textarea
                      id="message"
                      rows="8"
                      name="message"
                      required
                      placeholder={
                        idLanguage ? 'Tulis pesan anda' : 'Send us a message'
                      }
                      className="w-full px-4 py-2 3xl:px-8 3xl:py-4 mt-1 mb-3 md:my-6 text-black rounded-md md:rounded-lg text-xs lg:text-sm 3xl:text-xl placeholder:text-gray-battleship z-[999]"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full py-2 mb-0 border-2 rounded-lg md:py-4 md:mb-3 md:border-none border-white/50 bg-navy-blue px-14"
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
            <figure className="absolute bottom-0">
              <img src={wave} alt="wave" />
            </figure>
            <div className="h-[187px] w-[187px] bg-[#303095] rounded-full absolute right-[300] sm:right-[400px] md:right-[350px] lg:right-[460px] xl:right-[570px] 2xl:right-[650px] top-[100px] 3xl:right-[740px]" />
          </div>
        </div>

        {/* Notification */}
        {showSentNotif && (
          <div
            className={`z-[999] bg-black/50 w-full h-full  fixed top-0 flex items-center justify-center bg-transparant  transition-all duration-300 overflow-auto ${
              showSentNotif ? 'translate-x-0' : 'translate-x-full'
            } `}
          >
            <p className="px-4 py-2 mx-auto font-bold text-center text-white rounded-md shadow-md bg-navy-blue w-52">
              {idLanguage ? 'Pesan terkirim' : 'Message sent'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
