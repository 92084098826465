import React from 'react';

const ServiceInfoCard = ({
  serviceImage,
  serviceTitle,
  serviceDescription,
}) => {
  return (
    <div className="bg-white w-[295px] xs:w-[350px] sm:w-[295px] md:w-[320px] lg:w-[380px] xl:w-[370px] 2xl:w-[410px] 3xl:w-[450px] 4xl:w-[523px] rounded-lg md:rounded-xl 3xl:rounded-3xl 4xl:rounded-[32px] p-4 xs:p-5 3xl:p-12 shadow-lg">
      <img src={serviceImage} alt="instagram" className="w-7 lg:w-8" />
      <h2 className="my-1 text-base font-bold lg:my-2 2xl:my-3 3xl:my-5 4xl:my-6 lg:text-xl 3xl:text-2xl">
        {serviceTitle}
      </h2>
      <p className="text-xs font-medium text-justify lg:text-base 3xl:text-xl text-gray-battleship">
        {serviceDescription}
      </p>
    </div>
  );
};

export default ServiceInfoCard;
